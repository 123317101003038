// Variables
// --------------------------------------------------

/* Prefix
 * -------------------------------------------------- */

$prefix: 'statu-';


/* Theme Colors
 * -------------------------------------------------- */
$primary-purple:          var(--color-purple) !default;
$primary-green:           var(--color-green) !default;
$primary-blue:            var(--color-blue) !default;
$primary-orange:          var(--color-orange) !default;
$primary-yellow:          var(--color-yellow) !default;

$secondary-gray:          var(--color-gray-light) !default;
$secondary-beige:         var(--color-beige) !default;
$secondary-pink:          var(--color-pink) !default;
$secondary-blue:          var(--color-blue-light) !default;
$secondary-smoke:         var(--color-smoke) !default;

$color-text:              var(--color-gray) !default;
$color-black:             var(--color-black) !default;
$color-white:             var(--color-white) !default;

$theme-colors: ();


/* Breakpoints
 * -------------------------------------------------- */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1256px
);
$container-padding-x: 16px;


/* Spacing
 * -------------------------------------------------- */

$spacing: (
  'px': 1px,
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.5rem,
  '7': 1.75rem,
  '8': 2rem,
  '10': 2.5rem,
  '12': 3rem,
  '16': 4rem,
  '20': 5rem,
  '24': 6rem,
  '32': 8rem,
  '40': 10rem,
  '48': 12rem,
  '56': 14rem,
  '64': 16rem
);

$grid-gutter-width: 16px;
$gutters: $spacing;
$spacers: $spacing;


/* Others
 * -------------------------------------------------- */

$link-decoration: none;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-focus-width: none;
$enable-negative-margins: true;


/* Functions And Mixins
 * -------------------------------------------------- */

@import "static/bootstrap/scss/functions";
@import "static/bootstrap/scss/variables";
@import "static/bootstrap/scss/maps";
@import "static/bootstrap/scss/mixins";
