/* Prefix
 * -------------------------------------------------- */
/* Theme Colors
 * -------------------------------------------------- */
/* Breakpoints
 * -------------------------------------------------- */
/* Spacing
 * -------------------------------------------------- */
/* Others
 * -------------------------------------------------- */
/* Functions And Mixins
 * -------------------------------------------------- */
.error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  color: var(--color-black);
  padding: 104px 0;
}
.error__number {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 120px;
  line-height: 144px;
  color: var(--color-black);
}
.error__description {
  margin-bottom: 38px;
}
.error__link {
  --statu-link-color: var(--color-black);
  --statu-link-hover-color: var(--color-black);
}
.error__link:hover {
  text-decoration: underline;
}