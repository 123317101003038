@import "src/scss/variables";

.error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  color: $color-black;
  padding: 104px 0;

  &__number {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 120px;
    line-height: 144px;
    color: $color-black;
  }
  &__description {
    margin-bottom: 38px;
  }
  &__link {
    --statu-link-color: var(--color-black);
    --statu-link-hover-color: var(--color-black);

    &:hover {
      text-decoration: underline;
    }
  }
}